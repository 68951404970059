





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConfirmDialog extends Vue {
  @Prop({ default: "success" })
  private color: string;
  @Prop({ required: true })
  private text: string;
  @Prop({ default: "Да" })
  private positiveText: string;
  @Prop({ default: "Нет" })
  private negativeText: string;

  isDialogVisible = false;

  resolve?: any;

  async show(): Promise<boolean> {
    this.isDialogVisible = true;
    return new Promise(res => {
      this.resolve = res;
    });
  }

  onConfirm() {
    this.resolve(true);
    this.resolve = undefined;
    this.hideDialog();
  }

  onCancel() {
    this.hideDialog();
    if (this.resolve) {
      this.resolve(false);
      this.resolve = undefined;
    }
  }
  hideDialog() {
    this.isDialogVisible = false;
  }
  getIcon(color: string) {
    switch (color) {
      case "success":
        return "mdi-information-outline";
      case "warning":
        return "mdi-alert-octagon-outline";
      case "error":
        return "mdi-alert-outline";
      default:
        return "mdi-information-outline";
    }
  }
}
